<template>
  <div>
    <v-container>
      <h1 class="pb-6">My Documents</h1>

      <v-row>
        <v-col>
          <v-card rounded="lg" class="pa-6">
            <v-container>
              <v-row class="pt-6">
                <v-col cols="12">
                  <h4>Enter a Keyword</h4>
                  <v-text-field outlined></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <h4>Document Type</h4>
                  <v-select outlined></v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <h4>Filter By:</h4>
                  <v-text-field outlined></v-text-field>
                </v-col>
                <v-col class="d-flex justify-center pb-12" cols="12">
                  <v-btn depressed color="primary">Search</v-btn>
                </v-col>
              </v-row>
              <v-row class="d-flex justify-space-between pb-8">
                <div class="pb-2">
                  <v-btn class="mr-2 mb-2" depressed color="primary">
                    <v-icon left dark>mdi-eye</v-icon>
                    View
                  </v-btn>
                  <v-btn class="mr-2 mb-2" depressed color="primary">
                    <v-icon left dark>mdi-pencil</v-icon>
                    Edit
                  </v-btn>
                  <v-btn class="mr-2 mb-2" depressed color="primary">
                    <v-icon left dark>mdi-content-copy</v-icon>
                    Copy
                  </v-btn>
                  <v-btn class="mr-2 mb-2" depressed color="primary">
                    <v-icon left dark>mdi-download</v-icon>
                    Download
                  </v-btn>
                </div>
                <div>
                  <h4>Sort By</h4>
                  <v-select dense outlined></v-select>
                </div>
              </v-row>

              <v-data-table
                show-select
                :headers="headers"
                :items-per-page="5"
              ></v-data-table>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "Document ID #",
          align: "start",
          value: "1",
        },
        { text: "Document Name", value: "2" },
        { text: "Document Type", value: "3" },
        { text: "Last Modified", value: "4" },
      ],
    };
  },
};
</script>

<style></style>
